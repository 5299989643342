// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-tire-d-aile-js": () => import("./../../../src/pages/a-tire-d-aile.js" /* webpackChunkName: "component---src-pages-a-tire-d-aile-js" */),
  "component---src-pages-carte-blanche-js": () => import("./../../../src/pages/carte-blanche.js" /* webpackChunkName: "component---src-pages-carte-blanche-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-la-compagnie-js": () => import("./../../../src/pages/la-compagnie.js" /* webpackChunkName: "component---src-pages-la-compagnie-js" */),
  "component---src-pages-les-cours-js": () => import("./../../../src/pages/les-cours.js" /* webpackChunkName: "component---src-pages-les-cours-js" */),
  "component---src-pages-les-creations-js": () => import("./../../../src/pages/les-creations.js" /* webpackChunkName: "component---src-pages-les-creations-js" */),
  "component---src-pages-les-oiseaux-migrateurs-js": () => import("./../../../src/pages/les-oiseaux-migrateurs.js" /* webpackChunkName: "component---src-pages-les-oiseaux-migrateurs-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-nous-soutenir-js": () => import("./../../../src/pages/nous-soutenir.js" /* webpackChunkName: "component---src-pages-nous-soutenir-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

